<template>
    <el-row>
        <el-col :span="12">
            <i v-if="isCollapse" class="el-icon-s-unfold shrink" @click="shrink"></i>
            <i v-else class="el-icon-s-fold shrink" @click="shrink"></i>
        </el-col>
        <el-col :span="12">
            <el-dropdown @command="onUserMenu" style="float: right;">
                <span class="el-dropdown-link"><i class="el-icon-user-solid"></i> {{ user_name }}</span>
                <el-dropdown-menu slot="dropdown">
                    <el-dropdown-item command="home">主页</el-dropdown-item>
                    <el-dropdown-item command="updatepwd">修改密码</el-dropdown-item>
                    <el-dropdown-item command="logout" divided><span style="color: #fa541c">退出登录</span>
                    </el-dropdown-item>
                </el-dropdown-menu>
            </el-dropdown>
        </el-col>
    </el-row>
</template>

<script>

export default {
    data() {
        return {
            user_name: '',
        }
    },
    computed: {},
    // 父组件参数
    props: ['isCollapse'],
    // 初始化加载
    created: function () {
        let userinfo = this.Tool.get_l_cache('userinfo')
        if (userinfo !== null) {
            // console.log('获取用户姓名',userinfo)
            this.user_name = userinfo.name
        }

    },
    methods: {
        // 菜单收缩
        shrink() {
            if (this.$props.isCollapse) {
                this.$emit('navshrink', false)
            } else {
                this.$emit('navshrink', true)
            }
        },
        // 点击用户菜单
        onUserMenu(command) {
            if (command === 'home') {
                this.$router.push('/home')
            } else if (command === 'updatepwd') {
                this.$router.push('/home/password')
            } else if (command === 'logout') {
                this.logout()
            }
        },
        // 退出登录
        logout() {
            let postdata = {
                api_name: 'home.user.logout',
                token: this.Tool.get_l_cache('token'),
            }
            this.Tool.post_data('merchant', postdata, (json) => {
                if (json.code === 0) {
                    // 清空用户信息
                    this.Tool.del_l_cache('all')
                    this.Tool.del_s_cache('all')
                    // 提示并跳转登录页面
                    this.$message({
                        message: '您已退出登录', type: 'error',
                        duration: this.env.message_duration,
                        onClose: () => {
                            location.href = '/'
                        }
                    });
                } else {
                    this.$message.error(json.message);
                }
            })

        }
    }
}
</script>

<style scoped>
.el-dropdown-link {
    cursor: pointer;
    color: #758697;
}

.el-icon-arrow-down {
    font-size: 12px;
}

.demonstration {
    display: block;
    color: #8492a6;
    font-size: 14px;
    margin-bottom: 20px;
}

.shrink {
    font-size: 20px;
}

.shrink:hover {
    color: #409eff;
}
</style>
