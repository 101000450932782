<template>
    <el-container>
        <el-aside class="homeAside" style="max-width: 200px;height:100vh" :width="NavLeftWidth">
            <NavLeft :isCollapse="isCollapse"/>
        </el-aside>
        <el-container style="height:100vh">
            <el-header>
                <Header v-bind:isCollapse="isCollapse" v-on:navshrink="navshrink"/>
            </el-header>
            <el-main id="mainmain" :style="innerHeight">
                <div class="page-content">
                    <router-view></router-view>
                </div>
            </el-main>
            <el-footer height="50px">
                <span class="footer-sapn">2020-{{ year }} © 练市集 . 管理系统<a class="footer-sapn"
                                                                         style="color: #333; text-decoration:none; margin-left: 20px;"
                                                                         target="_blank"
                                                                         href="http://www.beian.miit.gov.cn">{{
                        this.env.ICP
                    }}<span
                            v-if="this.env.DE_BUG==='true'" style="color: #cf1322;">【开发测试商户版】</span></a>
                </span>
            </el-footer>
        </el-container>
    </el-container>
</template>

<script>
import NavLeft from '@/layouts/NavLeft'
import Header from '@/layouts/Header'

export default {
    components: {
        NavLeft,
        Header
    },
    data() {
        return {
            year: new Date().getFullYear(),
            isCollapse: false,
            NavLeftWidth: '200px',
            innerHeight: 'min-height: 400px;'
        }
    },
    // 初始化加载
    created: function () {
        console.log('主页-判断是否登录')
        const token = this.Tool.get_l_cache('token')
        if (token == null) {
            // 清空用户信息
            this.Tool.del_l_cache('all')
            this.Tool.del_s_cache('all')

            // 提示并跳转登录页面
            this.$message({
                message: '您未登录，请先登录', type: 'error',
                duration: this.env.message_duration,
                onClose: () => {
                    location.href = '/'
                }
            });
        } else {
            let postdata = {
                api_name: 'home.user.getinfo',
                token: token,
            }
            this.Tool.post_data('merchant', postdata, (json) => {
                // console.log('验证token',json)
                if (json.code !== 0) {
                    // 清空用户信息
                    this.Tool.del_l_cache('all')
                    this.Tool.del_s_cache('all')

                    // 提示并跳转登录页面
                    this.$message({
                        message: '登录过期，请重新', type: 'error',
                        duration: this.env.message_duration,
                        onClose: () => {
                            location.href = '/'
                        }
                    });
                }
            })
        }

        // 设置浏览器高度
        this.SetWindowHeight()
        // 菜单展开状态
        this.navshrink(this.Tool.get_s_cache('menu_isCollapse'))
    },
    mounted() {
        const thi = this
        // 监听窗口变化
        window.onresize = function temp() {
            if (!thi.timer) {
                thi.timer = true
                // 延迟操作
                setTimeout(function () {
                    // 重新计算高度
                    thi.SetWindowHeight()
                    thi.timer = false
                }, 400)
            }
        }
    },
    methods: {
        // 菜单收缩
        navshrink(data) {
            if (data) {
                this.$data.NavLeftWidth = '65px'
                this.$data.isCollapse = true
                this.Tool.set_s_cache('menu_isCollapse', true)
            } else {
                this.$data.NavLeftWidth = '200px'
                this.$data.isCollapse = false
                this.Tool.set_s_cache('menu_isCollapse', false)
            }
        },
        // 设置高度
        SetWindowHeight() {
            let innerHeight = 400
            if (window.innerHeight > innerHeight + 120) {
                innerHeight = window.innerHeight - 100
            }
            // console.log('设置内容高度', innerHeight)
            this.$data.innerHeight = 'min-height: ' + innerHeight + 'px;'
        }
    }
}
</script>

<style scoped>
.el-header {
    background-color: #fff;
    color: #333;
    line-height: 50px !important;
    height: 50px !important;
}

.el-footer {
    background-color: #b3c0d1;
    color: #333;
    line-height: 50px;
    line-height: 50px;
    font-size: 14px;
}

.el-aside {
    background-color: #263238;
    color: #333;
    /*line-height: 200px;*/
}

.el-main {
    background-color: #e9eef3;
    color: #333;
    /*line-height: 500px;*/
    /*height: 642px;*/
}
.homeAside::-webkit-scrollbar {
  display: none; /* Chrome Safari */
}
.homeAside {
  scrollbar-width: none; /* firefox */
  -ms-overflow-style: none; /* IE 10+ */
  overflow-x: hidden;
  overflow-y: auto;
}
</style>
